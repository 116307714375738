<template>
    <div>
        商户通知
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
}
</script>
<style scoped></style>